<template>
  <v-container fluid class="m-0 p-0">
    <v-row dense class="mt-1" v-if="uc != null">
      <v-col cols="12" class="">
        <v-card class="" v-bg:B>
          <v-card class="m-0 p-0" flat :color="hexTints(area.cor,0.95)">
            <v-container fluid class="">
              <v-row no-gutters align="center" justify="start" class="m-0 p-0">
                <v-col cols="auto" class="m-0 p-0 me-2">
                  <v-avatar dark :color="hexTints(area.cor,0.8)" size="26" class="m-0 p-0">
                    <i v-i:ic#add-circle#20 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                  </v-avatar>
                </v-col>
                <v-col cols="10" class="m-0 p-0 ps-1">
                  <div class="mt-0 line-height-1 f-lato fw-600 fs-9pt text-truncate">
                    Adicionar NOVO conteúdo
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card :color="hexTints(area.cor,0.98)">
            <v-container fluid class="">
              <v-row dense>
                <v-col cols="12" class="m-0 p-0 mt-1">
                  <p class="m-0 mb-1 p-0 fs-10pt f-lato fw-600">Texto do conteúdo</p>
                  <v-textarea rows="2" solo dense hide-details class="fs-11pt f-lato text-justify line-height-0" v-model="texto"></v-textarea>
                  <v-container fluid class="m-0 p-0 my-1" v-if="addDisabled && encontrado != null">
                    <v-row dense align="center" justify="start">
                      <v-col cols="" class="">
                        <v-card class="p-1 f-lato fs-10pt" :color="hexTints(area.cor,0.95)" v-cHex="'#cb1616'">
                          Conteúdo já existe
                          <span v-if="jaExisteNaEmenta">na ementa!</span>
                          <span v-if="!jaExisteNaEmenta">EM OUTRA ementa!</span>
                          <v-btn v-if="!jaExisteNaEmenta" color="" class="ms-3 me-0 ps-0 pe-1 fs-8pt f-lato text-nonecase" outlined x-small @click="adicionarJaExistente">
                            <i v-i:duo#plus-circle#18 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                            adicionar conteúdo já existente
                          </v-btn>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container fluid class="m-0 p-0 my-2">
                    <v-row dense align="center" justify="start">
                      <v-col cols="" class="text-right">
                        <v-btn :disabled="addDisabled" color="" class="me-0 ps-0 pe-1 fs-8pt f-lato text-nonecase" outlined x-small @click="$refs.promptdialogref.show(texto)">
                          <i v-i:duo#plus-circle#18 v-cHex="area.cor" class="btn-icon-left ps-1"></i>
                          adicionar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-card class="m-0 p-1 mt-1" flat>
            <v-icon style="vertical-align: middle">mdi-file-find</v-icon>
            <span class="fs-9pt f-roboto">
              Será que o <strong>conteúdo</strong> já existe?
              Antes de adicionar, confira a lista abaixo para evitar de adicionar um conteúdo equivalente a um já cadastrado.
            </span>
          </v-card>
          <div class="pt-2" v-if="sugestoes.length > 0">
            <p class="m-0 p-0 fs-10pt fw-600">Conteúdos parecidos já presentes nesta ementa:</p>
            <conteudoitemexiste :area="area" :uc="uc" :item="item" v-for="(item,key,index) in sugestoes" :key="index" @selConteudo="selConteudo"/>
          </div>
          <div class="pt-2" v-if="outrasSugestoes.length > 0">
            <p class="m-0 p-0 fs-10pt fw-600">Conteúdos parecidos presentes em outras UCs:</p>
            <conteudoitemexiste :area="area" :uc="uc" :item="item" v-for="(item,key,index) in outrasSugestoes" :key="index" @selConteudo="selConteudo"/>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <promptdialog ref="promptdialogref" @additem="adicionar"/>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray, ServerValue } from '@/firebasedb.js';
import ementaModel from "./ementa-model.js";

export default {
  name: "novoconteudo",
  components: {
    'promptdialog': () => import('./promptdialog.vue'),
    'conteudoitemexiste': () => import('./conteudo-item-existe.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      texto: "",
      ementaTexto: "",
      conteudos: {},
      conteudosParsed: [],
      sugestoes: [],
      outrasSugestoes: [],
      addDisabled: true,
      encontrado: null,
      jaExisteNaEmenta: false,
      qtdeConteudos: 0,
    }
  },
  watch: {
    texto() {
      this.filtrar();
    }
  },
  mounted: function() {
    var self = this;
    rdb.ref('/curriculo/conteudos/ucs/'+this.uc.id).on('value',function(snapshot) {
      var linha = snapshot.val();
      if(linha == null) {
        self.conteudos = {};
        self.qtdeConteudos = 0;
      } else {
        self.qtdeConteudos = linha.qtde;
        self.conteudos = linha.conteudos;
      }
      console.log("self.ementa",self.conteudos);
      self.build();
      self.refresh++;
    });
  },
  methods:{

    build() {
      var self = this;
      if(this.uc != null) {
        this.ementaTexto = this.uc.ementa.texto;
        this.parseEmenta();
      }
      //self.refresh++;
    },

    parseEmenta() {
      var self = this;
      self.conteudosParsed = [];
      var vet = this.ementaTexto.trim().split(";");
      if(vet.length < 2) {
        vet = this.ementaTexto.trim().split(".");
      }

      if(vet.length > 2) {
        for(var idx in vet) {
          var conteudo = vet[idx].trim();
          if(conteudo.charAt(conteudo.length-1) == ".") conteudo = conteudo.substr(0,conteudo.length-1);
          if(conteudo.charAt(conteudo.length-1) == ";") conteudo = conteudo.substr(0,conteudo.length-1);
          if(conteudo.trim() != "") {
            self.conteudosParsed.push(conteudo);
          }
        }
      }
      //this.refresh++;
      //console.log("self.conteudosParsed",self.conteudosParsed);
      this.filtrar();
    },

    filtrar() {
      //console.log("filtrar",this.filtro);
      if(this.texto.trim() == "" || this.texto.length < 3) {
        this.sugestoes = [];
        this.outrasSugestoes = [];
        this.addDisabled = true;
        this.encontrado = null;
        return;
      }
      this.sugestoes = [];
      this.outrasSugestoes = [];
      var bestValue = 0;
      var bestText = "";
      var todosConteudos = this.$store.state.conteudos.conteudos.conteudos;
      var textoNovo = this.texto.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      console.log("todosConteudos",todosConteudos);
      this.addDisabled = false;
      this.jaExisteNaEmenta = false;
      for(var idx in todosConteudos) {
        var textoOrig = todosConteudos[idx].texto.toUpperCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        if(textoNovo == textoOrig) {
          this.addDisabled = true;
          this.encontrado = todosConteudos[idx];
          if(todosConteudos[idx].ucs[this.uc.id] != undefined) {
            this.jaExisteNaEmenta = true;
          }
        }
        if(todosConteudos[idx].texto.toUpperCase().lastIndexOf(this.texto.toUpperCase()) != -1) {
          if(todosConteudos[idx].ucs[this.uc.id] != undefined) {
            this.sugestoes.push(todosConteudos[idx]);
          } else {
            this.outrasSugestoes.push(todosConteudos[idx]);
          }
        } else {
          var value = stringSimilarity.compareTwoStrings(todosConteudos[idx].texto.toUpperCase(),this.texto.toUpperCase());
          console.log(value);
          if(value > 0.4) {
            if(todosConteudos[idx].ucs[this.uc.id] != undefined) {
              this.sugestoes.push(todosConteudos[idx]);
            } else {
              this.outrasSugestoes.push(todosConteudos[idx]);
            }
          }
        }
      }
      console.log("this.sugestoes",this.sugestoes);
      console.log("this.outrasSugestoes",this.outrasSugestoes);
    },

    adicionar() {
      var self = this;
      console.log("adicionar");
      console.log("texto",self.texto);
      console.log("this.uc",this.uc);
      console.log("this.area",this.area);

      var todosConteudos = this.$store.state.conteudos.conteudos.conteudos;
      var textoNovo = self.texto.trim().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      for(var idx in todosConteudos) {
        var textoOrig = todosConteudos[idx].texto.toUpperCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        if(textoNovo == textoOrig) {
          this.toastD("Conteúdo já cadastrado");
          return;
        }
      }
      ementaModel.add({ uc: this.uc, texto: this.texto.trim() });
    },

    adicionarJaExistente() {
      console.log("adicionarJaExistente");
      console.log("this.encontrado",{ conteudo: this.encontrado, uc: this.uc });
      ementaModel.update({ conteudo: this.encontrado, uc: this.uc });
    },

    selConteudo(item) {
      console.log("item",item);
      this.texto = item.texto;
    }
  }
}
</script>

<style scoped>
</style>
